<template lang="pug">
  v-row
    v-col
      v-data-table(class="elevation-1" :footer-props="footer_props" :headers="headers" :items="actions" :loading="loading" loading-text="Загружается... Пожалуйста подождите")
        template(v-slot:item.active="{ item }")
          v-icon(color="green" v-if="item.active" title="Включена") mdi-checkbox-marked-circle
          v-icon(color="red" v-else title="Выключена") mdi-cancel
        template(v-slot:item.action="{ item }")
          router-link(:to="'/report/action/' + item.id" target='_blank') отчёт
</template>


<script>
    import { tradeactionService } from '@/_services'

    export default {
      data: () => ({
        headers: [
          { text: 'ID', value: 'id' },
          { text: 'Название', value: 'name' },
          { text: 'Проект', value: 'projectName' },
          { text: 'Сеть', value: 'networkName' },
          { text: 'Начало', value: 'startDate' },
          { text: 'Окончание', value: 'endDate' },
          { text: 'Состояние', value: 'active' },
          { text: 'Комментарий', value: 'comment' },
          { text: '', value: 'action' }
        ],
        actions: [],
        loading: false,
        footer_props: {
            'items-per-page-options': [10, 25, 50, 100]
        }
      }),
      mounted() {
        this.load()
      },
      methods: {
        load() {
          this.loading = true
          tradeactionService.list().then(data => {
            this.actions = data
            this.loading = false
          })
        },
      }
    }
</script>